import FormattedMargin from 'components/FormattedMargin';
import FormattedPercent from 'components/FormattedPercent';
import useItemPriceHistory from 'hooks/useItemPriceHistory';
import { useMemo } from 'react';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, YAxis } from 'recharts';
import { IOsrsItemDerived } from 'types/item';
import { computeHistoryMetadata } from 'utils/ItemAnalysis';
import { safeSubtract, safeDivide } from 'utils/SafeMath';

interface IExpandableRowComponentProps {
    data?: IOsrsItemDerived;
}

const ExpandableRowComponent: React.FC<IExpandableRowComponentProps> = ({ data }) => {
    // Field only optional due to DataTable component requiring passing as JSX until version 7 releases
    const item = data!!;
    const { histories, loading } = useItemPriceHistory({
        live: false,
        itemId: item.id,
    });

    const dailyHistoryMetadata = useMemo(() => computeHistoryMetadata(histories['5m']), [histories]);
    const weeklyHistoryMetadata = useMemo(() => computeHistoryMetadata(histories['1h']), [histories]);

    const margins = useMemo(
        () => ({
            '5m': safeSubtract(item.summaries['5m']?.avgHighPrice, item.latestPrice.lowPrice),
            '1h': safeSubtract(item.summaries['1h']?.avgHighPrice, item.latestPrice.lowPrice),
            '6h': safeSubtract(item.summaries['6h']?.avgHighPrice, item.latestPrice.lowPrice),
            spread: {
                all: safeSubtract(dailyHistoryMetadata?.high.highest, dailyHistoryMetadata?.low.lowest),
                '10': safeSubtract(dailyHistoryMetadata?.high.percentiles[10], dailyHistoryMetadata?.low.percentiles[10]),
                '25': safeSubtract(dailyHistoryMetadata?.high.percentiles[25], dailyHistoryMetadata?.low.percentiles[25]),
            },
        }),
        [item, dailyHistoryMetadata]
    );

    return (
        <div className="is-flex-column item-row-expanded">
            <div className="is-flex-row">
                <div className="is-flex-grow">
                    <div className="subtitle">Low Prices</div>
                    <ul>
                        <li>
                            <b>Latest:</b> {item.latestPrice.lowPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Average (5m):</b> {item.summaries['5m']?.avgLowPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Average (1h):</b> {item.summaries['1h']?.avgLowPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Average (6h):</b> {item.summaries['6h']?.avgLowPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Lowest (24h):</b> {dailyHistoryMetadata?.low.lowest?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Bottom 10% (24h):</b> {dailyHistoryMetadata?.low.percentiles[10]?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Bottom 25% (24h):</b> {dailyHistoryMetadata?.low.percentiles[25]?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Trend (24h):</b> <FormattedMargin margin={dailyHistoryMetadata?.low.trend} /> (
                            <FormattedPercent percent={safeDivide(dailyHistoryMetadata?.low.trend, dailyHistoryMetadata?.low.startingPrice)} />)
                        </li>
                        <li>
                            <b>Trend (7d):</b> <FormattedMargin margin={weeklyHistoryMetadata?.low.trend} /> (
                            <FormattedPercent percent={safeDivide(weeklyHistoryMetadata?.low.trend, weeklyHistoryMetadata?.low.startingPrice)} />)
                        </li>
                    </ul>
                </div>

                <div className="is-flex-grow">
                    <div className="subtitle">High Prices</div>
                    <ul>
                        <li>
                            <b>Latest:</b> {item.latestPrice.highPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Average (5m):</b> {item.summaries['5m']?.avgHighPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Average (1h):</b> {item.summaries['1h']?.avgHighPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Average (6h):</b> {item.summaries['6h']?.avgHighPrice?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Highest (24h):</b> {dailyHistoryMetadata?.high.highest?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Top 10% (24h):</b> {dailyHistoryMetadata?.high.percentiles[10]?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Top 25% (24h):</b> {dailyHistoryMetadata?.high.percentiles[25]?.toLocaleString() ?? '--'}
                        </li>
                        <li>
                            <b>Trend (24h):</b> <FormattedMargin margin={dailyHistoryMetadata?.high.trend} /> (
                            <FormattedPercent percent={safeDivide(dailyHistoryMetadata?.high.trend, dailyHistoryMetadata?.high.startingPrice)} />)
                        </li>

                        <li>
                            <b>Trend (7d):</b> <FormattedMargin margin={weeklyHistoryMetadata?.high.trend} /> (
                            <FormattedPercent percent={safeDivide(weeklyHistoryMetadata?.high.trend, weeklyHistoryMetadata?.high.startingPrice)} />)
                        </li>
                    </ul>
                </div>

                <div className="is-flex-grow">
                    <div className="subtitle">Margins</div>
                    <ul>
                        <li>
                            <b>Latest:</b> <FormattedMargin margin={item.priceMetadata.margin} /> (
                            <FormattedPercent percent={item.priceMetadata.roi} />)
                        </li>
                        <li>
                            <b>Latest - Average (5m):</b> <FormattedMargin margin={margins['5m']} /> (
                            <FormattedPercent percent={safeDivide(margins['5m'], item?.summaries['5m']?.avgLowPrice)} />)
                        </li>
                        <li>
                            <b>Latest - Average (1h):</b> <FormattedMargin margin={margins['1h']} /> (
                            <FormattedPercent percent={safeDivide(margins['1h'], item?.summaries['1h']?.avgLowPrice)} />)
                        </li>
                        <li>
                            <b>Latest - Average (6h):</b> <FormattedMargin margin={margins['6h']} /> (
                            <FormattedPercent percent={safeDivide(margins['6h'], item?.summaries['6h']?.avgLowPrice)} />)
                        </li>
                        <li>
                            <b>0-100% Spread (24h):</b> <FormattedMargin margin={margins.spread.all} /> (
                            <FormattedPercent percent={safeDivide(margins.spread.all, dailyHistoryMetadata?.low.lowest)} />)
                        </li>
                        <li>
                            <b>10-90% Spread (24h):</b> <FormattedMargin margin={margins.spread[10]} /> (
                            <FormattedPercent percent={safeDivide(margins.spread['10'], dailyHistoryMetadata?.low.percentiles[10])} />)
                        </li>
                        <li>
                            <b>25-75% Spread (24h):</b> <FormattedMargin margin={margins.spread[25]} /> (
                            <FormattedPercent percent={safeDivide(margins.spread['25'], dailyHistoryMetadata?.low.percentiles[25])} />)
                        </li>
                    </ul>
                </div>
            </div>
            {!loading && (
                <div className="is-flex-row is-flex-grow">
                    <div className="price-chart">
                        <b>Past 24h</b>
                        <ResponsiveContainer>
                            <LineChart
                                data={histories['5m']}
                                margin={{
                                    left: 15,
                                    top: 20,
                                    right: 100,
                                    bottom: 20,
                                }}
                            >
                                <Line type="linear" isAnimationActive={false} dot={false} connectNulls dataKey="avgLowPrice" stroke={'#ff0000'} />
                                <Line type="linear" isAnimationActive={false} dot={false} connectNulls dataKey="avgHighPrice" stroke={'#00ff00'} />
                                <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={item.latestPrice.highPrice}
                                    strokeDasharray="3 3"
                                    stroke={'#00ff00'}
                                    label={{ value: 'High Price', fill: '#00ff00', position: 'right' }}
                                />
                                <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={item.latestPrice.lowPrice}
                                    strokeDasharray="3 3"
                                    stroke={'#ff0000'}
                                    label={{ value: 'Low Price', fill: '#ff0000', position: 'right' }}
                                />
                                <CartesianGrid stroke="#ffffff30" />
                                <YAxis
                                    interval="preserveStartEnd"
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tick={{ fill: '#ccc' }}
                                    tickFormatter={(timestamp: number) => timestamp.toLocaleString()}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="price-chart">
                        <b>Past 7d</b>
                        <ResponsiveContainer>
                            <LineChart
                                data={histories['1h']}
                                margin={{
                                    left: 15,
                                    top: 20,
                                    right: 100,
                                    bottom: 20,
                                }}
                            >
                                <Line type="linear" isAnimationActive={false} dot={false} connectNulls dataKey="avgLowPrice" stroke={'#ff0000'} />
                                <Line type="linear" isAnimationActive={false} dot={false} connectNulls dataKey="avgHighPrice" stroke={'#00ff00'} />
                                <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={item.latestPrice.highPrice}
                                    strokeDasharray="3 3"
                                    stroke={'#00ff00'}
                                    label={{ value: 'High Price', fill: '#00ff00', position: 'right' }}
                                />
                                <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={item.latestPrice.lowPrice}
                                    strokeDasharray="3 3"
                                    stroke={'#ff0000'}
                                    label={{ value: 'Low Price', fill: '#ff0000', position: 'right' }}
                                />
                                <CartesianGrid stroke="#ffffff30" />
                                <YAxis
                                    interval="preserveStartEnd"
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tick={{ fill: '#ccc' }}
                                    tickFormatter={(timestamp: number) => timestamp.toLocaleString()}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExpandableRowComponent;
