import classNames from 'classnames';
import { ItemsContext } from 'context/ItemsContext';
import { useContext } from 'react';

interface IFavoriteButtonProps {
    itemId: number;
}

const FavoriteButton: React.FC<IFavoriteButtonProps> = ({ itemId }) => {
    const { favorites, toggleFavorite } = useContext(ItemsContext);

    const isFavorite = favorites.has(itemId);

    return (
        <button onClick={() => toggleFavorite(itemId)} className={classNames({ 'is-active': isFavorite })}>
            <i className={classNames('fa-heart', { fas: isFavorite, far: !isFavorite })} />
        </button>
    );
};

export default FavoriteButton;
