import { Fragment, useContext, useMemo } from 'react';

import useItems from 'hooks/useItems';
import { Page } from 'types/page';
import { computeItemMetadata } from 'utils/ItemAnalysis';
import { IOsrsItemDerived } from 'types/item';

import './itemListPage.scss';
import { Helmet } from 'react-helmet';
import ItemList from 'components/ItemList';
import { IItemsContext, ItemsContext } from 'context/ItemsContext';

type QuickFilters = 'all' | 'favorites' | 'watched';

interface IItemListPageProps {
    quickFilterType?: QuickFilters;
}

const applyQuickFilter = (filter: QuickFilters, itemsContext: IItemsContext): ((item: IOsrsItemDerived | undefined) => boolean) => {
    switch (filter) {
        case 'favorites':
            return (item) => (item ? itemsContext.favorites.has(item.id) : false);
        case 'watched':
            return (item) => (item ? itemsContext.watched.has(item.id) : false);
        default:
            return () => true;
    }
};

const ItemListPage: Page<IItemListPageProps> = ({ quickFilterType = 'all' }) => {
    const {
        items: rawItems,
        status,
        loading,
    } = useItems({
        live: true,
        refreshDuration: 30000,
    });

    const itemsContext = useContext(ItemsContext);
    const quickFilter = applyQuickFilter(quickFilterType, itemsContext);

    const items = useMemo(
        () =>
            [...(rawItems?.values() ?? [])]
                .map(computeItemMetadata)
                .filter((item) => item)
                .filter(quickFilter),
        [rawItems, quickFilterType] //eslint-disable-line
    ) as IOsrsItemDerived[];

    return (
        <Fragment>
            <div className="container">
                {loading && status}
                {!loading && items && <ItemList items={items} title={'Item Flipping'} subtitle={'All Items'} pageId={quickFilterType} />}
            </div>
            <Helmet>
                <title>Item Search - OSRS GoldMind</title>
            </Helmet>
        </Fragment>
    );
};

export default ItemListPage;
