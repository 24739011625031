import { useEffect, useReducer, Reducer } from 'react';

import cacheItem from 'hooks/useCache';

import { HistoryTimestep, IOsrsItemHistories, IOsrsItemPriceSummary } from 'types/item';
import { fetchItemTimeseries } from 'data/osrswiki';
import useInterval from './useInterval';

enum Statuses {
    Loading = 'Loading',
    Refreshing = 'Refreshing',
    Loaded = 'Loaded',
}

interface IUseItemPriceHistoryResponse {
    histories: IOsrsItemHistories;
    loading: boolean;
    status?: string;
}

interface IUseItemPriceHistoryParams {
    itemId: number;
    live?: boolean;
    refreshDuration?: number;
}

interface HistoryState {
    status: string;
    histories: {
        [key in HistoryTimestep]: IOsrsItemPriceSummary[] | undefined;
    };
    promises: {
        [key in HistoryTimestep]: Promise<IOsrsItemPriceSummary[]>;
    };
}

export default function useItemPriceHistory({ live = false, refreshDuration = 0, itemId }: IUseItemPriceHistoryParams): IUseItemPriceHistoryResponse {
    // Combine the two state values using a reducer to minimize re-renders
    const [state, setState] = useReducer<Reducer<HistoryState, Partial<HistoryState>>>((state, newState) => ({ ...state, ...newState }), {
        status: Statuses.Loading,
        histories: {
            '5m': undefined,
            '1h': undefined,
            '6h': undefined,
            '24h': undefined,
        },
        promises: {
            '5m': cacheItem(`item-price-history-5m-${itemId}`, () => fetchItemTimeseries(itemId, '5m'), 10000)[0],
            '1h': cacheItem(`item-price-history-1h-${itemId}`, () => fetchItemTimeseries(itemId, '1h'), 300000)[0],
            '6h': cacheItem(`item-price-history-6h-${itemId}`, () => fetchItemTimeseries(itemId, '6h'), 3600000)[0],
            '24h': cacheItem(`item-price-history-24h-${itemId}`, () => fetchItemTimeseries(itemId, '24h'), 21600000)[0],
        },
    });

    // Fetch non-latest prices and cache them for appropriate times

    useInterval(
        () => {
            const promises = {
                '5m': cacheItem(`item-price-history-5m-${itemId}`, () => fetchItemTimeseries(itemId, '5m'), 10000)[0],
                '1h': cacheItem(`item-price-history-1h-${itemId}`, () => fetchItemTimeseries(itemId, '1h'), 300000)[0],
                '6h': cacheItem(`item-price-history-6h-${itemId}`, () => fetchItemTimeseries(itemId, '6h'), 3600000)[0],
                '24h': cacheItem(`item-price-history-24h-${itemId}`, () => fetchItemTimeseries(itemId, '24h'), 21600000)[0],
            };
            setState({ promises, status: Statuses.Refreshing });
        },
        live ? refreshDuration : 0
    );

    useEffect(() => {
        (async () => {
            const histories = {
                '5m': await state.promises['5m'],
                '1h': await state.promises['1h'],
                '6h': await state.promises['6h'],
                '24h': await state.promises['24h'],
            };
            setState({
                histories,
                status: Statuses.Loaded,
            });
        })();
    }, [state.promises]);

    return {
        histories: state.histories,
        loading: state.status === Statuses.Loading,
        status: state.status,
    };
}
