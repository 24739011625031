import React, { useEffect, useState } from 'react';

interface IRelativeTimestampProps {
    timestamp?: Date;
}

var rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

var getRelativeTime = (d1: Date, d2 = new Date()) => {
    const units: { [key: string]: number } = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: (24 * 60 * 60 * 1000 * 365) / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000,
    };

    var elapsed = d1.getTime() - d2.getTime();

    // "Math.abs" accounts for both "past" & "future" scenarios
    for (var u in units)
        if (Math.abs(elapsed) > units[u] || u === 'second') return rtf.format(Math.round(elapsed / units[u]), u as Intl.RelativeTimeFormatUnit);
};

const RelativeTimestamp: React.FC<IRelativeTimestampProps> = (props) => {
    const { timestamp } = props;
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        const interval = setInterval(() => setNow(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    if (!timestamp) return <span>Unknown</span>;

    return <span>{getRelativeTime(timestamp, now)}</span>;
};

export default RelativeTimestamp;
