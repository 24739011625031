import React, { Fragment } from 'react';
import { Router, RouteComponentProps, Link } from '@reach/router';

import ItemPage from 'pages/ItemPage';

import './app.scss';
import ItemListPage from 'pages/ItemListPage';
import { ItemsContextProvider } from 'context/ItemsContext';

const NotFound: React.FC<RouteComponentProps> = () => {
    return (
        <div className="App">
            <p>Page Not Found!</p>
        </div>
    );
};

function App() {
    return (
        <Fragment>
            <div className="header">
                <div className="home-icon">
                    <i className="fas fa-coins fa-2x" />
                </div>
                <div className="home-logo">GoldMind</div>
                <div className="navbar">
                    <Link className="navbar-item" to="/">
                        All Items
                    </Link>
                    <Link className="navbar-item" to="/favorites">
                        Favorites
                    </Link>
                    <Link className="navbar-item" to="/watched">
                        Watched Items
                    </Link>
                </div>
            </div>
            <div className="container">
                <ItemsContextProvider>
                    <Router>
                        <ItemListPage path="/" />
                        <ItemListPage path="/favorites" quickFilterType="favorites" />
                        <ItemListPage path="/watched" quickFilterType="watched" />
                        <ItemPage path="/item/:itemId" itemId="" />
                        <NotFound default />
                    </Router>
                </ItemsContextProvider>
            </div>
        </Fragment>
    );
}

export default App;
