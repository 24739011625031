import classNames from 'classnames';
import { MouseEventHandler } from 'react';

import './modal.scss';

interface IModalProps {
    active: boolean;
    closeOnBackgroundClicked?: boolean;
    onCloseClicked?: MouseEventHandler;
}

const Modal: React.FC<IModalProps> = ({ active, closeOnBackgroundClicked = false, onCloseClicked, children }) => {
    return (
        <div className={classNames('is-modal', { 'is-active': active })}>
            <div className="modal-background" onClick={closeOnBackgroundClicked ? onCloseClicked : undefined}></div>
            <div className="modal-close" onClick={onCloseClicked}>
                <i className="fas fa-times fa-2x" />
            </div>
            <div className="modal-content">{children}</div>
        </div>
    );
};

export default Modal;
