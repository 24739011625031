import { Page } from 'types/page';
import RelativeTimestamp from 'components/RelativeTimestamp';
import FormattedPercent from 'components/FormattedPercent';
import FormattedMargin from 'components/FormattedMargin';
import { IOsrsItemDerived } from 'types/item';

interface IItemPageHeaderProps {
    item: IOsrsItemDerived;
}

const ItemPageSummary: Page<IItemPageHeaderProps> = ({ item }) => {
    return (
        <div className="item-data is-flex-row is-full-width is-align-start item-summary">
            <div className="is-flex-column">
                <div className="subtitle">Latest Prices</div>
                <div>
                    <b>Instant-Buy Price:</b> {item.latestPrice.highPrice?.toLocaleString() ?? 'Unknown'}
                </div>
                <div className="indented">
                    <em>
                        Latest trade: <RelativeTimestamp timestamp={new Date((item.latestPrice.highTime ?? 0) * 1000)} />
                    </em>
                </div>
                <div>
                    <b>Instant-Sell Price:</b> {item.latestPrice.lowPrice?.toLocaleString() ?? 'Unknown'}
                </div>
                <div className="indented">
                    <em>
                        Latest trade: <RelativeTimestamp timestamp={new Date((item.latestPrice.lowTime ?? 0) * 1000)} />
                    </em>
                </div>
            </div>
            <div className="is-flex-column">
                <div className="subtitle">Average Buy Price</div>
                <div className="indented">
                    <b>5 minutes:</b> {item.summaries['5m']?.avgHighPrice?.toLocaleString() ?? 'Unknown'} (
                    <FormattedPercent percent={item.priceMetadata.trends.high['5m']} />)
                </div>
                <div className="indented">
                    <b>1 hour:</b> {item.summaries['1h']?.avgHighPrice?.toLocaleString() ?? 'Unknown'} (
                    <FormattedPercent percent={item.priceMetadata.trends.high['1h']} />)
                </div>
                <div className="indented">
                    <b>24 hour:</b> {item.summaries['24h']?.avgHighPrice?.toLocaleString() ?? 'Unknown'} (
                    <FormattedPercent percent={item.priceMetadata.trends.high['24h']} />)
                </div>
                <div className="subtitle">Average Sell Price</div>
                <div className="indented">
                    <b>5 minutes:</b> {item.summaries['5m']?.avgLowPrice?.toLocaleString() ?? 'Unknown'} (
                    <FormattedPercent percent={item.priceMetadata.trends.low['5m']} />)
                </div>
                <div className="indented">
                    <b>1 hour:</b> {item.summaries['1h']?.avgLowPrice?.toLocaleString() ?? 'Unknown'} (
                    <FormattedPercent percent={item.priceMetadata.trends.low['1h']} />)
                </div>
                <div className="indented">
                    <b>24 hour:</b> {item.summaries['24h']?.avgLowPrice?.toLocaleString() ?? 'Unknown'} (
                    <FormattedPercent percent={item.priceMetadata.trends.low['24h']} />)
                </div>
            </div>
            <div className="is-flex-column">
                <div>
                    <b>Hourly Volume:</b> {item.priceMetadata.hourlyVolume?.toLocaleString() ?? 0}
                </div>
                <div>
                    <b>Daily Volume:</b> {item.priceMetadata.dailyVolume?.toLocaleString() ?? 0}
                </div>
                <div>
                    <b>Margin:</b> <FormattedMargin margin={item.priceMetadata.margin} />
                </div>
                <div>
                    <b>ROI:</b> <FormattedPercent percent={item.priceMetadata.roi} />
                </div>
                <div>
                    <b>Potential Profit:</b> <FormattedMargin margin={item.priceMetadata.potentialProfit} />
                </div>
            </div>
            <div className="is-flex-column">
                <div>
                    <b>Buy Limit:</b> {item.metadata.buyLimit?.toLocaleString() ?? 'Unknown'}
                </div>
                <div>
                    <b>Store Price:</b> {item.metadata.storePrice.toLocaleString()}
                </div>
                <div>
                    <b>High Alch Price:</b> {item.metadata.highAlchPrice.toLocaleString()}
                </div>
                <div>
                    <b>Members:</b> {item.metadata.members ? 'Yes' : 'No'}
                </div>
                <div>
                    <b>Examine:</b> {item.metadata.examine}
                </div>
            </div>
        </div>
    );
};

export default ItemPageSummary;
