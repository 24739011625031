import { Fragment } from 'react';
import Helmet from 'react-helmet';

import './itemPage.scss';
import useItems from 'hooks/useItems';
import useItemPriceHistory from 'hooks/useItemPriceHistory';
import { Page } from 'types/page';
import { computeHistoryMetadata, computeItemMetadata } from 'utils/ItemAnalysis';
import ItemPageSummary from './summary';
import ItemPageChart from './charts';
import RelativeTimestamp from 'components/RelativeTimestamp';
import { HistoryTimestep } from 'types/item';
import usePersistedState from 'hooks/usePersistedState';
import FavoriteButton from 'components/FavoriteButton';
import WatchButton from 'components/WatchButton';
import classNames from 'classnames';

interface IItemPageProps {
    itemId: string;
}

const ItemPage: Page<IItemPageProps> = (props) => {
    const itemId = parseInt(props.itemId);
    const {
        items,
        status: itemStatus,
        loading: itemLoading,
    } = useItems({
        live: true,
        refreshDuration: 30000,
    });
    const {
        histories,
        status: historyStatus,
        loading: historyLoading,
    } = useItemPriceHistory({
        live: true,
        refreshDuration: 60000,
        itemId,
    });

    const item = computeItemMetadata(items?.get(itemId));

    const status = itemLoading ? itemStatus : historyStatus;

    const [timestep, setTimestep] = usePersistedState<HistoryTimestep>('item-chart-timestep', '5m');
    const history = histories[timestep];
    const historyMetadata = computeHistoryMetadata(history ?? []);

    return (
        <Fragment>
            <div className="container">
                {!item && status}
                {item && (
                    <div className="container">
                        <div className="is-flex is-space-between">
                            <div className="item-header">
                                <img alt={item.metadata.name} src={`https://oldschool.runescape.wiki/images/${item.metadata.wikiIconUrl}?`} className="icon" />
                                <div className="item-name">{item.metadata.name}</div>
                                <div className="item-members">
                                    <span className="icon">
                                        <i className={classNames("fas fa-star",{'gold': item.metadata.members})}/>
                                    </span>
                                </div>
                                <div className="item-id">(#{item.id})</div>
                                <FavoriteButton itemId={item.id} />
                                <WatchButton itemId={item.id} />
                            </div>
                            <div>
                                <em>
                                    Last updated: <RelativeTimestamp timestamp={item.lastUpdated} />
                                </em>
                            </div>
                        </div>
                        <ItemPageSummary item={item} />
                        {!historyLoading && history && historyMetadata && (
                            <Fragment>
                                <ItemPageChart
                                    priceHistory={history}
                                    priceHistoryMetadata={historyMetadata}
                                    timestep={timestep}
                                    onTimestepChange={setTimestep}
                                    latestPrice={item.latestPrice}
                                />
                            </Fragment>
                        )}
                    </div>
                )}
            </div>
            <Helmet>
                <title>{`${item?.metadata.name ?? 'Unknown Item'} - OSRS GoldMind`}</title>
            </Helmet>
        </Fragment>
    );
};

export default ItemPage;
