import { FC } from 'react';

interface IFormattedMarginProps {
    margin?: number;
}

const FormattedMargin: FC<IFormattedMarginProps> = (props) => {
    if (typeof props.margin === 'undefined') return <span>---</span>;
    const colorClass = props.margin < 0 ? 'negative' : props.margin > 0 ? 'positive' : undefined;
    const prefix = props.margin >= 0 ? '+' : '';
    return <span className={colorClass}>{`${prefix}${Math.floor(props.margin).toLocaleString()}`}</span>;
};

export default FormattedMargin;
