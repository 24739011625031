import { FC } from 'react';

interface IFormattedPercentProps {
    percent?: number;
}

const FormattedPercent: FC<IFormattedPercentProps> = (props) => {
    if (typeof props.percent === 'undefined') return <span>---</span>;
    const colorClass = props.percent < 0 ? 'negative' : props.percent > 0 ? 'positive' : undefined;
    const prefix = props.percent >= 0 ? '+' : '';
    return <span className={colorClass}>{`${prefix}${(props.percent * 100).toFixed(2)}%`}</span>;
};

export default FormattedPercent;
