import React, { Fragment, ReactElement, Reducer, useEffect, useMemo, useReducer } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { HistoryTimestep, IOsrsItemLatestPrice, IOsrsItemPriceSummary, IOsrsItemPriceSummaryDerived } from 'types/item';
import msToDuration from 'utils/MsToDuration';
import FormattedMargin from 'components/FormattedMargin';
import FormattedPercent from 'components/FormattedPercent';
import { computeHistoryMetadata } from 'utils/ItemAnalysis';
import classNames from 'classnames';

interface IItemPageChartProps {
    priceHistory: IOsrsItemPriceSummary[];
    priceHistoryMetadata: IOsrsItemPriceSummaryDerived;
    timestep: HistoryTimestep;
    onTimestepChange?: (value: HistoryTimestep) => void;
    latestPrice?: IOsrsItemLatestPrice;
}

interface ITimestampAxisTickProps {
    x: number;
    y: number;
    index: number;
    payload: {
        value: number;
    };
}

interface IZoomState {
    start: number;
    end: number;
}

const TimestampAxisTick =
    (timestep: HistoryTimestep) =>
    (props: ITimestampAxisTickProps): ReactElement<SVGElement> => {
        const { x, y, payload, index } = props;

        const timestamp = new Date((payload?.value ?? 0) * 1000);
        const timeLabel = timestamp.toLocaleTimeString();
        const dateLabel = timestamp.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
        });

        const showTime = timestep === '5m';
        const showDate = timestep !== '5m' || index === 0 || timestamp.getHours() === 0;

        const dateY = showTime ? 16 : 0;

        return (
            <g transform={`translate(${x},${y})`}>
                {showTime && (
                    <text x={0} y={0} dy={16} textAnchor="middle" fill="#ccc">
                        {timeLabel}
                    </text>
                )}
                {showDate && (
                    <text x={0} y={dateY} dy={16} textAnchor="middle" fill="#ccc">
                        {dateLabel}
                    </text>
                )}
            </g>
        );
    };

const getTimestampAxisTicks = (items: IOsrsItemPriceSummary[], timestep: HistoryTimestep): number[] => {
    if (!items || items.length === 0) {
        return [];
    }

    const earliest = new Date(items[0].timestamp * 1000);
    const latest = new Date(items[items.length - 1].timestamp * 1000);
    const duration = latest.getTime() - earliest.getTime();

    const ticks: number[] = [];

    if (timestep === '5m') {
        // Step 3h at a time, starting from the next multiple of 3h after earliest
        const startHour = Math.ceil((earliest.getHours() + earliest.getMinutes() / 60) / 3) * 3;
        const count = Math.floor(duration / (60 * 60 * 1000 * 3));
        for (let i = 0; i < count; i++) {
            const tick = new Date(earliest);
            tick.setHours(startHour + i * 3, 0, 0, 0);
            ticks.push(tick.getTime() / 1000);
        }
    } else if (timestep === '1h') {
        // Step 1 day at a time, starting from the next midnight after earliest
        const count = Math.floor(duration / (60 * 60 * 1000 * 24));
        for (let i = 0; i <= count; i++) {
            const tick = new Date(earliest);
            tick.setHours(24 * (i + 1), 0, 0, 0);
            ticks.push(tick.getTime() / 1000);
        }
    } else if (timestep === '6h') {
        // Step 7 days at a time, starting from today and stepping backwards
        const count = Math.floor(duration / (60 * 60 * 1000 * 24 * 7));
        for (let i = 0; i <= count; i++) {
            const tick = new Date(latest);
            tick.setHours(0 - 24 * 7 * i, 0, 0, 0);
            ticks.unshift(tick.getTime() / 1000);
        }
    }

    return ticks;
};

const formatName = (value: string): string => {
    const formattedNames: { [key: string]: string } = {
        avgLowPrice: 'Instant-Sell',
        avgHighPrice: 'Instant-Buy',
        'movingAverages.low': 'Instant-Sell (Moving Average)',
        'movingAverages.high': 'Instant-Buy (Moving Average)',
        lowPriceVolume: 'Instant-Sell Volume',
        highPriceVolume: 'Instant-Buy Volume',
    };

    return formattedNames[value];
};

const tooltipFormatter = (value: number, name: string) => {
    let formattedName = formatName(name);
    let formattedValue = name === 'lowPriceVolume' ? -value : value;

    return [formattedValue.toLocaleString(), formattedName];
};

const tooltipLabelFormatter = (value: number) => {
    return new Date(value * 1000).toLocaleString('default', {
        dateStyle: 'long',
        timeStyle: 'medium',
    });
};

const legendFormatter = (value: any, entry: any) => {
    const { color } = entry;

    return <span style={{ color }}>{formatName(value)}</span>;
};

interface PriceData extends IOsrsItemPriceSummary {
    movingAverages: {
        low?: number;
        high?: number;
    };
}

// Flip the low price volumes to properly render the bar charts
// TODO: Calculate the SMA and EMA
const zoomPriceData = (prices: IOsrsItemPriceSummary[], zoom?: IZoomState): IOsrsItemPriceSummary[] => {
    if (!zoom) return prices;
    return prices.filter((price) => price.timestamp >= zoom.start && price.timestamp <= zoom.end);
};

const transformPrices = (prices: IOsrsItemPriceSummary[], metadata?: IOsrsItemPriceSummaryDerived): PriceData[] => {
    return prices.map((price, index) => ({
        avgHighPrice: price.avgHighPrice,
        avgLowPrice: price.avgLowPrice,
        highPriceVolume: price.highPriceVolume,
        lowPriceVolume: price.lowPriceVolume ? -price.lowPriceVolume : price.lowPriceVolume,
        timestamp: price.timestamp,
        movingAverages: {
            low: metadata?.low.movingAverage[index],
            high: metadata?.high.movingAverage[index],
        },
    }));
};

interface IPriceReferenceLineProps {
    value?: number;
    label?: string;
    color?: string;
}

const PriceReferenceLine = ({ value, label = '', color = '#ccc', ...props }: IPriceReferenceLineProps): JSX.Element => {
    return (
        <ReferenceLine
            {...props}
            ifOverflow="extendDomain"
            y={value}
            strokeDasharray="3 3"
            stroke={color}
            label={{ value: label, fill: color, position: 'right' }}
        />
    );
};

interface IItemPageChartState {
    zoom?: IZoomState;
    refAreaLeft?: number;
    refAreaRight?: number;
    lastClickTime: number;
}

const onChartMouseUp = (setState: (state: Partial<IItemPageChartState>) => any, lastClickTime: number, refAreaLeft?: number, refAreaRight?: number) => () => {
    const now = new Date().getTime();
    if (now - lastClickTime < 500) {
        setState({
            refAreaLeft: undefined,
            refAreaRight: undefined,
            zoom: undefined,
            lastClickTime: now,
        });
    } else if (refAreaLeft && refAreaRight && refAreaLeft !== refAreaRight) {
        setState({
            zoom: {
                start: Math.min(refAreaLeft, refAreaRight),
                end: Math.max(refAreaLeft, refAreaRight),
            },
            refAreaLeft: undefined,
            refAreaRight: undefined,
            lastClickTime: now,
        });
    } else {
        setState({
            refAreaLeft: undefined,
            refAreaRight: undefined,
            lastClickTime: now,
        });
    }
};

const ItemPageChart: React.FC<IItemPageChartProps> = (props) => {
    const { latestPrice, priceHistory, timestep, onTimestepChange } = props;

    // Due to complex state and slow re-renders, use a reducer to batch updates and minimize re-render calls
    const [state, setState] = useReducer<Reducer<IItemPageChartState, Partial<IItemPageChartState>>>((state, newState) => ({ ...state, ...newState }), {
        zoom: undefined,
        refAreaLeft: undefined,
        refAreaRight: undefined,
        lastClickTime: 0,
    });

    const { refAreaLeft, refAreaRight, zoom, lastClickTime } = state;

    useEffect(() => setState({ zoom: undefined }), [timestep]);

    const trimmedPrices = useMemo(() => zoomPriceData(priceHistory, zoom), [priceHistory, zoom]);
    const metadata = useMemo(() => computeHistoryMetadata(trimmedPrices), [trimmedPrices]);
    const prices = useMemo(() => transformPrices(trimmedPrices, metadata), [trimmedPrices, metadata]);
    const ticks = useMemo(() => getTimestampAxisTicks(prices, timestep), [prices, timestep]);

    const tickElement = TimestampAxisTick(timestep);
    const buyColor = '#ff8000';
    const sellColor = '#60ff00';
    const topColor = '#ff2000';
    const bottomColor = '#20ccaa';

    return (
        <Fragment>
            {prices.length > 0 && (
                <div className="is-flex-row">
                    <div className="is-flex-column is-flex-grow">
                        {onTimestepChange && (
                            <div className="chart-timestep-select">
                                <div className="button-group">
                                    <div className="label">Chart Timestep: </div>
                                    <button className={classNames({ 'is-active': timestep === '5m' })} onClick={() => onTimestepChange('5m')}>
                                        5 Minutes
                                    </button>
                                    <button className={classNames({ 'is-active': timestep === '1h' })} onClick={() => onTimestepChange('1h')}>
                                        1 Hour
                                    </button>
                                    <button className={classNames({ 'is-active': timestep === '6h' })} onClick={() => onTimestepChange('6h')}>
                                        6 Hours
                                    </button>
                                    <button className={classNames({ 'is-active': timestep === '24h' })} onClick={() => onTimestepChange('24h')}>
                                        24 Hours
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="chart-container-wrapper">
                            <div className="chart-container-responsive">
                                <ResponsiveContainer>
                                    <LineChart
                                        syncId="itemChart"
                                        data={prices}
                                        margin={{ top: 0, right: 75, left: 0, bottom: 0 }}
                                        onMouseDown={(e: any) => (e ? setState({ refAreaLeft: e.activeLabel }) : undefined)}
                                        onMouseMove={(e: any) => (refAreaLeft && e ? setState({ refAreaRight: e.activeLabel }) : undefined)}
                                        onMouseUp={onChartMouseUp(setState, lastClickTime, refAreaLeft, refAreaRight)}
                                    >
                                        {PriceReferenceLine({ value: latestPrice?.highPrice, color: buyColor, label: 'Latest Buy' })}
                                        {PriceReferenceLine({ value: latestPrice?.lowPrice, color: sellColor, label: 'Latest Sell' })}
                                        {PriceReferenceLine({ value: metadata?.high.percentiles[25], color: topColor, label: 'Top 25%' })}
                                        {PriceReferenceLine({ value: metadata?.low.percentiles[25], color: bottomColor, label: 'Bottom 25%' })}

                                        <Line type="linear" isAnimationActive={false} dot={false} connectNulls dataKey="avgLowPrice" stroke={sellColor} />
                                        <Line type="linear" isAnimationActive={false} dot={false} connectNulls dataKey="avgHighPrice" stroke={buyColor} />
                                        <Line
                                            type="monotone"
                                            isAnimationActive={false}
                                            dot={false}
                                            connectNulls
                                            dataKey="movingAverages.high"
                                            stroke={`${sellColor}80`}
                                        />
                                        <Line
                                            type="monotone"
                                            isAnimationActive={false}
                                            dot={false}
                                            connectNulls
                                            dataKey="movingAverages.low"
                                            stroke={`${buyColor}80`}
                                        />
                                        <CartesianGrid stroke="#ffffff30" />
                                        <XAxis
                                            padding={{ left: 0, right: 20 }}
                                            dataKey="timestamp"
                                            ticks={ticks}
                                            tick={tickElement}
                                            scale="time"
                                            type="number"
                                            domain={['auto', 'auto']}
                                            fill="#ccc"
                                            tickCount={8}
                                        />
                                        <YAxis interval="preserveStartEnd" type="number" domain={['auto', 'auto']} tick={{ fill: '#ccc' }} />
                                        <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
                                        <Legend formatter={legendFormatter} />
                                        {refAreaLeft && refAreaRight && <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="chart-container-wrapper">
                            <div className="chart-container-responsive">
                                <ResponsiveContainer>
                                    <BarChart
                                        syncId="itemChart"
                                        stackOffset="sign"
                                        data={prices}
                                        margin={{ top: 0, right: 75, left: 0, bottom: 0 }}
                                        onMouseDown={(e: any) => (e ? setState({ refAreaLeft: e.activeLabel }) : undefined)}
                                        onMouseMove={(e: any) => (refAreaLeft && e ? setState({ refAreaRight: e.activeLabel }) : undefined)}
                                        onMouseUp={onChartMouseUp(setState, lastClickTime, refAreaLeft, refAreaRight)}
                                    >
                                        <Bar stackId="a" isAnimationActive={false} dataKey="lowPriceVolume" fill={sellColor} />
                                        <Bar stackId="a" isAnimationActive={false} dataKey="highPriceVolume" fill={buyColor} />
                                        <CartesianGrid stroke="#ffffff30" />
                                        <XAxis
                                            padding={{ left: 0, right: 20 }}
                                            dataKey="timestamp"
                                            ticks={ticks}
                                            tick={tickElement}
                                            scale="time"
                                            type="number"
                                            domain={['auto', 'auto']}
                                            fill="#ccc"
                                            tickCount={8}
                                        />
                                        <ReferenceLine y={0} stroke="#000" />
                                        <YAxis tick={{ fill: '#ccc' }} tickFormatter={(value) => Math.abs(value).toString()} />
                                        <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
                                        <Legend formatter={legendFormatter} />
                                        {refAreaLeft && refAreaRight && <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="price-chart-sidebar">
                        <div className="subtitle">At A Glance</div>
                        <div>
                            <b>From </b>
                            {new Date(prices[0].timestamp * 1000).toLocaleString('default', {
                                dateStyle: 'long',
                                timeStyle: 'medium',
                            })}
                        </div>
                        <div>
                            <b>To </b>
                            {new Date(prices[prices.length - 1].timestamp * 1000).toLocaleString('default', {
                                dateStyle: 'long',
                                timeStyle: 'medium',
                            })}
                        </div>
                        {metadata?.durationMs && (
                            <div className="indented">
                                <b>Duration: </b>
                                {msToDuration(metadata.durationMs)}
                            </div>
                        )}
                        <div className="category">Buy Prices</div>
                        <div className="indented">
                            <b>Starting: </b>
                            {metadata?.high.startingPrice?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Ending: </b>
                            {metadata?.high.endingPrice?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Trend: </b>
                            <FormattedMargin margin={metadata?.high.trend} /> (
                            <FormattedPercent
                                percent={metadata?.high.trend && prices[0].avgHighPrice ? metadata.high.trend / prices[0].avgHighPrice : undefined}
                            />
                            )
                        </div>
                        <div className="indented">
                            <b>Highest: </b>
                            {metadata?.high.highest?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Top 5%: </b>
                            {metadata?.high.percentiles[5]?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Top 10%: </b>
                            {metadata?.high.percentiles[10]?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Top 25%: </b>
                            {metadata?.high.percentiles[25]?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="category">Sell Prices</div>
                        <div className="indented">
                            <b>Starting: </b>
                            {metadata?.low.startingPrice?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Ending: </b>
                            {metadata?.low.endingPrice?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Trend: </b>
                            <FormattedMargin margin={metadata?.low.trend} /> (
                            <FormattedPercent percent={metadata?.low.trend && prices[0].avgLowPrice ? metadata.low.trend / prices[0].avgLowPrice : undefined} />
                            )
                        </div>
                        <div className="indented">
                            <b>Lowest: </b>
                            {metadata?.low.lowest?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Bottom 5%: </b>
                            {metadata?.low.percentiles[5]?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Bottom 10%: </b>
                            {metadata?.low.percentiles[10]?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Bottom 25%: </b>
                            {metadata?.low.percentiles[25]?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="category">Volume</div>
                        <div className="indented">
                            <b>Buy Volume: </b>
                            {metadata?.high.volume?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Buy Volume/hr: </b>
                            {metadata?.high.volume ? Math.floor(metadata.high.volume / (metadata.durationMs / (60 * 60 * 1000))).toLocaleString() : 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Sell Volume: </b>
                            {metadata?.low.volume?.toLocaleString() ?? 'Unknown'}
                        </div>
                        <div className="indented">
                            <b>Sell Volume/hr: </b>
                            {metadata?.low.volume ? Math.floor(metadata.low.volume / (metadata.durationMs / (60 * 60 * 1000))).toLocaleString() : 'Unknown'}
                        </div>
                        <div className="category">Classifications</div>
                        <div className="indented">
                            <em>Coming Soon!</em>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ItemPageChart;
