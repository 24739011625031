import Modal from 'components/Modal';
import { useState, useEffect } from 'react';
import { IConfigurableFilter, INumericFilter } from 'types/filters';

interface IItemFilterModalProps {
    active: boolean;
    filter?: IConfigurableFilter;
    onClose: (filterChanged: boolean, newFilter?: IConfigurableFilter) => any;
}

const ItemFilterModal: React.FC<IItemFilterModalProps> = ({ active, filter, onClose }) => {
    const [highPriceMin, setHighPriceMin] = useState<number>(0);
    const [highPriceMax, setHighPriceMax] = useState<number>(0);
    const [lowPriceMin, setLowPriceMin] = useState<number>(0);
    const [lowPriceMax, setLowPriceMax] = useState<number>(0);
    const [avgHighPriceMin, setAvgHighPriceMin] = useState<number>(0);
    const [avgHighPriceMax, setAvgHighPriceMax] = useState<number>(0);
    const [avgLowPriceMin, setAvgLowPriceMin] = useState<number>(0);
    const [avgLowPriceMax, setAvgLowPriceMax] = useState<number>(0);
    const [volumeMin, setVolumeMin] = useState<number>(0);
    const [volumeMax, setVolumeMax] = useState<number>(0);
    const [buyLimitMin, setBuyLimitMin] = useState<number>(0);
    const [buyLimitMax, setBuyLimitMax] = useState<number>(0);
    const [marginMin, setMarginMin] = useState<number>(0);
    const [marginMax, setMarginMax] = useState<number>(0);
    const [roiMin, setRoiMin] = useState<number>(0);
    const [roiMax, setRoiMax] = useState<number>(0);
    const [avgMarginMin, setAvgMarginMin] = useState<number>(0);
    const [avgMarginMax, setAvgMarginMax] = useState<number>(0);
    const [avgRoiMin, setAvgRoiMin] = useState<number>(0);
    const [avgRoiMax, setAvgRoiMax] = useState<number>(0);
    const [potentialProfitMin, setPotentialProfitMin] = useState<number>(0);
    const [potentialProfitMax, setPotentialProfitMax] = useState<number>(0);
    const [velocityMin, setVelocityMin] = useState<number>(0);
    const [velocityMax, setVelocityMax] = useState<number>(0);
    const [f2pOnly, setF2pOnly] = useState<boolean>(true);

    useEffect(() => {
        setHighPriceMin(filter?.highPrice?.min ?? 0);
        setHighPriceMax(filter?.highPrice?.max ?? 0);
        setLowPriceMin(filter?.lowPrice?.min ?? 0);
        setLowPriceMax(filter?.lowPrice?.max ?? 0);
        setAvgHighPriceMin(filter?.avgHighPrice?.min ?? 0);
        setAvgHighPriceMax(filter?.avgHighPrice?.max ?? 0);
        setAvgLowPriceMin(filter?.avgLowPrice?.min ?? 0);
        setAvgLowPriceMax(filter?.avgLowPrice?.max ?? 0);
        setVolumeMin(filter?.dailyVolume?.min ?? 0);
        setVolumeMax(filter?.dailyVolume?.max ?? 0);
        setBuyLimitMin(filter?.buyLimit?.min ?? 0);
        setBuyLimitMax(filter?.buyLimit?.max ?? 0);
        setMarginMin(filter?.margin?.min ?? 0);
        setMarginMax(filter?.margin?.max ?? 0);
        setRoiMin(filter?.roi?.min ?? 0);
        setRoiMax(filter?.roi?.max ?? 0);
        setAvgMarginMin(filter?.avgMargin?.min ?? 0);
        setAvgMarginMax(filter?.avgMargin?.max ?? 0);
        setAvgRoiMin(filter?.avgRoi?.min ?? 0);
        setAvgRoiMax(filter?.avgRoi?.max ?? 0);
        setPotentialProfitMin(filter?.potentialProfit?.min ?? 0);
        setPotentialProfitMax(filter?.potentialProfit?.max ?? 0);
        setVelocityMin(filter?.velocity?.min ?? 0);
        setVelocityMax(filter?.velocity?.max ?? 0);
        setF2pOnly(filter?.f2pOnly ?? false)
    }, [filter, active]);

    const stateOnChangeNumberHandler = (dispatch: (val: number) => void) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = parseInt(e.target.value, 10);
            dispatch(!isNaN(val) ? val : 0);
        };
    };

    return (
        <Modal
            active={active}
            onCloseClicked={() => {
                onClose(false);
            }}
        >
            <div className="is-flex-column is-full-width filter-modal">
                <div className="subtitle">Item Filter</div>
                <div className="is-flex-row is-full-width">
                    <div className="is-flex-column is-flex-grow">
                        <label>High Price (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={highPriceMin || ''}
                                onChange={stateOnChangeNumberHandler(setHighPriceMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={highPriceMax || ''}
                                onChange={stateOnChangeNumberHandler(setHighPriceMax)}
                            ></input>
                        </div>

                        <label>Low Price (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={lowPriceMin || ''}
                                onChange={stateOnChangeNumberHandler(setLowPriceMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={lowPriceMax || ''}
                                onChange={stateOnChangeNumberHandler(setLowPriceMax)}
                            ></input>
                        </div>

                        <label>1h⌖ High Price (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgHighPriceMin || ''}
                                onChange={stateOnChangeNumberHandler(setAvgHighPriceMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgHighPriceMax || ''}
                                onChange={stateOnChangeNumberHandler(setAvgHighPriceMax)}
                            ></input>
                        </div>

                        <label>1h⌖ Low Price (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgLowPriceMin || ''}
                                onChange={stateOnChangeNumberHandler(setAvgLowPriceMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgLowPriceMax || ''}
                                onChange={stateOnChangeNumberHandler(setAvgLowPriceMax)}
                            ></input>
                        </div>

                        <label>Daily Volume (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={volumeMin || ''}
                                onChange={stateOnChangeNumberHandler(setVolumeMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={volumeMax || ''}
                                onChange={stateOnChangeNumberHandler(setVolumeMax)}
                            ></input>
                        </div>

                        <label>Buy Limit(Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={buyLimitMin || ''}
                                onChange={stateOnChangeNumberHandler(setBuyLimitMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={buyLimitMax || ''}
                                onChange={stateOnChangeNumberHandler(setBuyLimitMax)}
                            ></input>
                        </div>
                        <div className="is-flex-row is-align-center">
                            <input checked={f2pOnly} onChange={e => setF2pOnly(e.target.checked)} type="checkbox" />
                            <label>Free To Play Only</label>
                        </div>
                    </div>
                    <div className="is-flex-column is-flex-grow">
                        <label>Margin (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={marginMin || ''}
                                onChange={stateOnChangeNumberHandler(setMarginMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={marginMax || ''}
                                onChange={stateOnChangeNumberHandler(setMarginMax)}
                            ></input>
                        </div>

                        <label>ROI % (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={roiMin || ''}
                                onChange={stateOnChangeNumberHandler(setRoiMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={roiMax || ''}
                                onChange={stateOnChangeNumberHandler(setRoiMax)}
                            ></input>
                        </div>

                        <label>1h⌖ Margin (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgMarginMin || ''}
                                onChange={stateOnChangeNumberHandler(setAvgMarginMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgMarginMax || ''}
                                onChange={stateOnChangeNumberHandler(setAvgMarginMax)}
                            ></input>
                        </div>

                        <label>1h⌖ ROI % (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgRoiMin || ''}
                                onChange={stateOnChangeNumberHandler(setAvgRoiMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={avgRoiMax || ''}
                                onChange={stateOnChangeNumberHandler(setAvgRoiMax)}
                            ></input>
                        </div>

                        <label>Potential Profit (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={potentialProfitMin || ''}
                                onChange={stateOnChangeNumberHandler(setPotentialProfitMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={potentialProfitMax || ''}
                                onChange={stateOnChangeNumberHandler(setPotentialProfitMax)}
                            ></input>
                        </div>

                        <label>Margin * Daily Volume (Min / Max)</label>
                        <div className="is-flex-row">
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={velocityMin || ''}
                                onChange={stateOnChangeNumberHandler(setVelocityMin)}
                            ></input>
                            <input
                                className="is-flex-grow"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={velocityMax || ''}
                                onChange={stateOnChangeNumberHandler(setVelocityMax)}
                            ></input>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="is-left">
                        <button
                            className="is-dangerous"
                            onClick={() => {
                                setHighPriceMin(0);
                                setHighPriceMax(0);
                                setLowPriceMin(0);
                                setLowPriceMax(0);
                                setAvgHighPriceMin(0);
                                setAvgHighPriceMax(0);
                                setAvgLowPriceMin(0);
                                setAvgLowPriceMax(0);
                                setVolumeMin(0);
                                setVolumeMax(0);
                                setBuyLimitMin(0);
                                setBuyLimitMax(0);
                                setMarginMin(0);
                                setMarginMax(0);
                                setRoiMin(0);
                                setRoiMax(0);
                                setAvgMarginMin(0);
                                setAvgMarginMax(0);
                                setAvgRoiMin(0);
                                setAvgRoiMax(0);
                                setPotentialProfitMin(0);
                                setPotentialProfitMax(0);
                                setVelocityMin(0);
                                setVelocityMax(0);
                                setF2pOnly(false);
                            }}
                        >
                            Clear Filter
                        </button>
                    </div>
                    <div className="is-right">
                        <button
                            className="is-primary"
                            onClick={() => {
                                const highPrice: INumericFilter | undefined =
                                    highPriceMin || highPriceMax
                                        ? {
                                              min: highPriceMin || undefined,
                                              max: highPriceMax || undefined,
                                          }
                                        : undefined;

                                const lowPrice: INumericFilter | undefined =
                                    lowPriceMin || lowPriceMax
                                        ? {
                                              min: lowPriceMin || undefined,
                                              max: lowPriceMax || undefined,
                                          }
                                        : undefined;
                                
                                const avgHighPrice: INumericFilter | undefined =
                                    avgHighPriceMin || avgHighPriceMax
                                        ? {
                                                min: avgHighPriceMin || undefined,
                                                max: avgHighPriceMax || undefined,
                                            }
                                        : undefined;

                                const avgLowPrice: INumericFilter | undefined =
                                    avgLowPriceMin || avgLowPriceMax
                                        ? {
                                                min: avgLowPriceMin || undefined,
                                                max: avgLowPriceMax || undefined,
                                            }
                                        : undefined;

                                const dailyVolume: INumericFilter | undefined =
                                    volumeMin || volumeMax
                                        ? {
                                              min: volumeMin || undefined,
                                              max: volumeMax || undefined,
                                          }
                                        : undefined;

                                const buyLimit: INumericFilter | undefined =
                                    buyLimitMin || buyLimitMax
                                        ? {
                                              min: buyLimitMin || undefined,
                                              max: buyLimitMax || undefined,
                                          }
                                        : undefined;

                                const margin: INumericFilter | undefined =
                                    marginMin || marginMax
                                        ? {
                                              min: marginMin || undefined,
                                              max: marginMax || undefined,
                                          }
                                        : undefined;

                                const roi: INumericFilter | undefined =
                                    roiMin || roiMax
                                        ? {
                                              min: roiMin || undefined,
                                              max: roiMax || undefined,
                                          }
                                        : undefined;

                                const avgMargin: INumericFilter | undefined =
                                        avgMarginMin || avgMarginMax
                                            ? {
                                                  min: avgMarginMin || undefined,
                                                  max: avgMarginMax || undefined,
                                              }
                                            : undefined;
    
                                    const avgRoi: INumericFilter | undefined =
                                        avgRoiMin || avgRoiMax
                                            ? {
                                                  min: avgRoiMin || undefined,
                                                  max: avgRoiMax || undefined,
                                              }
                                            : undefined;

                                const potentialProfit: INumericFilter | undefined =
                                    potentialProfitMin || potentialProfitMax
                                        ? {
                                              min: potentialProfitMin || undefined,
                                              max: potentialProfitMax || undefined,
                                          }
                                        : undefined;

                                const velocity: INumericFilter | undefined =
                                    velocityMin || velocityMax
                                        ? {
                                              min: velocityMin || undefined,
                                              max: velocityMax || undefined,
                                          }
                                        : undefined;

                                const newFilter: IConfigurableFilter | undefined =
                                    highPrice || lowPrice || avgHighPrice || avgLowPrice || dailyVolume || buyLimit || margin || roi || avgMargin || avgRoi || potentialProfit || velocity || f2pOnly
                                        ? {
                                              highPrice,
                                              lowPrice,
                                              avgHighPrice,
                                              avgLowPrice,
                                              dailyVolume,
                                              buyLimit,
                                              margin,
                                              roi,
                                              avgMargin,
                                              avgRoi,
                                              potentialProfit,
                                              velocity,
                                              f2pOnly
                                          }
                                        : undefined;

                                onClose(true, newFilter);
                            }}
                        >
                            Apply Filter
                        </button>
                        <button onClick={() => onClose(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ItemFilterModal;
