import escapeStringRegexp from 'escape-string-regexp';
import { INameFilter, ItemFilter } from 'types/filters';
import { IOsrsItemDerived } from 'types/item';

const NameFilter = (filter?: INameFilter): ItemFilter => {
    if (!filter || (filter.name?.length ?? 0) === 0) return () => true;

    let escaped = escapeStringRegexp(filter.name!!);
    if (filter.fuzzy) {
        escaped = escaped.replace(/\s+/g, '.+?');
    }
    const regex = new RegExp(escaped, 'i');

    return (value?: IOsrsItemDerived) => {
        return value ? regex.test(value.metadata.name) : false;
    };
};

export default NameFilter;
