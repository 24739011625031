import { IOsrsItem, IOsrsItemDerived, IOsrsItemPriceSummary, IOsrsItemPriceSummaryDerived } from 'types/item';
import simpleMovingAverage from './MovingAverages';
import { safeAdd, safeDivide, safeFloor, safeMultiply, safeSubtract } from './SafeMath';

const TAX_EXEMPT_ITEMS = new Set<number>([
    1755,  // Chisel
    5325,  // Gardening trowel
    1785,  // Glassblowing pipe
    2347,  // Hammer
    1733,  // Needle
    233,   // Pestle and mortar
    5341,  // Rake
    5329,  // Secateurs
    5343,  // Seed dibber
    1735,  // Shears
    952,   // Spade
    5331,  // Watering can (empty)
    13190  // Old school bond
])

const computeTrend = (latestPrice?: number, avgPrice?: number): number | undefined => {
    if (!latestPrice || !avgPrice) return;
    if (latestPrice === avgPrice) return 0;
    return (latestPrice - avgPrice) / avgPrice;
};

const computeTax = (id: number, price: number | undefined): number => {
    if (TAX_EXEMPT_ITEMS.has(id)) return 0;
    if ((price ?? 0) >= 100) {
        return safeFloor(safeDivide(price, 100)) ?? 0;
    }
    return 0;
}

const getTax = (item: IOsrsItem): number => {
    return computeTax(item.id, item.latestPrice.highPrice);
}

const computeApproxHourlyValues = (item: IOsrsItem): [number | undefined, number | undefined, number | undefined, number | undefined, number | undefined, number | undefined] => {
    const SAFETY_MARGIN = 20;
    
    if (!item.pastHourSummaries) return [undefined, undefined, undefined, undefined, undefined, undefined]
    const totalLowVolume = item.pastHourSummaries?.map(s => s.lowPriceVolume).filter(v => v != null).reduce((acc, val) => safeAdd(acc, val));
    const totalHighVolume = item.pastHourSummaries?.map(s => s.highPriceVolume).filter(v => v != null).reduce((acc, val) => safeAdd(acc, val));
    const targetVolume = item.metadata.buyLimit ?? 1

    const lowPercent = totalLowVolume ? Math.max(SAFETY_MARGIN, Math.min(100, Math.round(100 * (targetVolume / totalLowVolume)))) : undefined;
    const highPercent = totalHighVolume ? 100 - Math.max(SAFETY_MARGIN, Math.min(100, Math.round(100 * (targetVolume / totalHighVolume)))) : undefined;
    const lowPrice = lowPercent != null ? computePercentile(item.pastHourSummaries, "low", lowPercent) : undefined;
    const highPrice = highPercent != null ? computePercentile(item.pastHourSummaries, "high", highPercent) : undefined;
    const margin = safeSubtract(safeSubtract(highPrice, computeTax(item.id, highPrice)), lowPrice);
    const roi = safeDivide(margin, lowPrice);

    const lowTimestamp = lowPrice ? item.pastHourSummaries.find(s => s.avgLowPrice && s.avgLowPrice <= lowPrice)?.timestamp : undefined;
    const highTimestamp = highPrice ? item.pastHourSummaries.find(s => s.avgHighPrice && s.avgHighPrice >= highPrice)?.timestamp : undefined;

    if (item.id === 27612) {
        console.log(item.metadata.name, item.pastHourSummaries)
        console.log(totalLowVolume, totalHighVolume, lowPercent, lowTimestamp, highPercent, highTimestamp, lowPrice, highPrice)
    }
    return [lowPrice, lowTimestamp, highPrice, highTimestamp, margin, roi]
}

export function computeItemMetadata(item?: IOsrsItem): IOsrsItemDerived | undefined {
    if (!item) return item;

    const dailyVolume = safeAdd(item.summaries['24h']?.highPriceVolume, item.summaries['24h']?.lowPriceVolume);
    const hourlyVolume = safeAdd(item.summaries['1h']?.highPriceVolume, item.summaries['1h']?.lowPriceVolume);
    const tax = getTax(item);
    const margin = safeSubtract(safeSubtract(item.latestPrice.highPrice, tax), item.latestPrice.lowPrice);
    const roi = safeDivide(margin, item.latestPrice?.lowPrice);
    const dailyVelocity = safeMultiply(margin, dailyVolume);
    const potentialProfit = safeMultiply(margin, item.metadata.buyLimit);
    const profitPercentOfDailyVelocity = safeDivide(potentialProfit, dailyVelocity);
    const buySellRatio = safeDivide(item.summaries['24h']?.highPriceVolume, item.summaries['24h']?.lowPriceVolume);
    const [
        approxHourlyLowPrice, 
        approxHourlyLowPriceTimestamp, 
        approxHourlyHighPrice, 
        approxHourlyHighPriceTimestamp, 
        approxHourlyMargin, 
        approxHourlyRoi
    ] = computeApproxHourlyValues(item)
    const trends = {
        high: {
            '5m': computeTrend(item.latestPrice.highPrice, item.summaries['5m']?.avgHighPrice),
            '1h': computeTrend(item.latestPrice.highPrice, item.summaries['1h']?.avgHighPrice),
            '6h': computeTrend(item.latestPrice.highPrice, item.summaries['6h']?.avgHighPrice),
            '24h': computeTrend(item.latestPrice.highPrice, item.summaries['24h']?.avgHighPrice),
        },
        low: {
            '5m': computeTrend(item.latestPrice.lowPrice, item.summaries['5m']?.avgLowPrice),
            '1h': computeTrend(item.latestPrice.lowPrice, item.summaries['1h']?.avgLowPrice),
            '6h': computeTrend(item.latestPrice.lowPrice, item.summaries['6h']?.avgLowPrice),
            '24h': computeTrend(item.latestPrice.lowPrice, item.summaries['24h']?.avgLowPrice),
        },
    };
    
    return {
        ...item,
        priceMetadata: {
            dailyVolume,
            hourlyVolume,
            tax,
            taxable: tax > 0,
            margin,
            roi,
            potentialProfit,
            profitPercentOfDailyVelocity,
            dailyVelocity,
            buySellRatio,
            trends,
            approxHourlyLowPrice,
            approxHourlyLowPriceTimestamp,
            approxHourlyHighPrice,
            approxHourlyHighPriceTimestamp,
            approxHourlyMargin,
            approxHourlyRoi
        },
    };
}

// Compute the percentile based on the volume rather than the array of prices
// to account for items with swing-y price changes
const computePercentile = (history: IOsrsItemPriceSummary[], type: 'high' | 'low', percentile: number): number => {
    if (history.length === 0) return 0;

    interface PriceVolume {
        price: number;
        volume: number;
    }

    let priceVolumes: PriceVolume[] = [];
    let totalVolume = 0;

    for (let priceData of history) {
        const price = type === 'high' ? priceData.avgHighPrice : priceData.avgLowPrice;
        const volume = type === 'high' ? priceData.highPriceVolume : priceData.lowPriceVolume;
        if (!price || !volume) continue;
        priceVolumes.push({ price, volume });
        totalVolume += volume;
    }

    priceVolumes.sort((a, b) => (a.price ?? 0) - (b.price ?? 0));

    if (percentile === 0) return priceVolumes[0]?.price
    if (percentile === 100) return priceVolumes[priceVolumes.length - 1]?.price

    let targetVolume = Math.floor(totalVolume * (percentile / 100));
    for (let priceVolume of priceVolumes) {
        if (targetVolume < priceVolume.volume) return priceVolume.price;
        targetVolume -= priceVolume.volume;
    }

    return 0;
};

const computeMovingAverage = (prices: (number | undefined)[], timestamps: number[], window: number): (number | undefined)[] => {
    return simpleMovingAverage(prices, window);
};

export function computeHistoryMetadata(history?: IOsrsItemPriceSummary[]): IOsrsItemPriceSummaryDerived | undefined {
    if (!history || history.length === 0) return undefined;

    const highPrices = history.map((entry) => entry.avgHighPrice);
    const lowPrices = history.map((entry) => entry.avgLowPrice);
    const highPriceVolume = history.map((entry) => entry.highPriceVolume ?? 0).reduce((a, b) => a + b, 0);
    const lowPriceVolume = history.map((entry) => entry.lowPriceVolume ?? 0).reduce((a, b) => a + b, 0);
    const timestamps = history.map((entry) => entry.timestamp);

    const processedHigh: number[] = highPrices.filter((price) => price) as number[];
    const processedLow: number[] = lowPrices.filter((price) => price) as number[];

    const startingHigh = processedHigh[0];
    const startingLow = processedLow[0];
    const endingHigh = processedHigh[processedHigh.length - 1];
    const endingLow = processedLow[processedLow.length - 1];

    processedHigh.sort();
    processedLow.sort();

    const metadata: IOsrsItemPriceSummaryDerived = {
        durationMs: (history[history.length - 1].timestamp - history[0].timestamp) * 1000,
        high: {
            highest: processedHigh.length > 0 ? processedHigh[processedHigh.length - 1] : undefined,
            percentiles: {
                5: computePercentile(history, 'high', 95),
                10: computePercentile(history, 'high', 90),
                25: computePercentile(history, 'high', 75),
            },
            movingAverage: computeMovingAverage(highPrices, timestamps, 12),
            trend: endingHigh && startingHigh ? endingHigh - startingHigh : undefined,
            volume: highPriceVolume,
            startingPrice: startingHigh,
            endingPrice: endingHigh,
        },
        low: {
            lowest: processedLow[0],
            percentiles: {
                5: computePercentile(history, 'low', 5),
                10: computePercentile(history, 'low', 10),
                25: computePercentile(history, 'low', 25),
            },
            movingAverage: computeMovingAverage(lowPrices, timestamps, 12),
            trend: endingLow && startingLow ? endingLow - startingLow : undefined,
            volume: lowPriceVolume,
            startingPrice: startingLow,
            endingPrice: endingLow,
        },
    };
    return metadata;
}
