import { IConfigurableFilter, ItemFilter } from 'types/filters';
import { IOsrsItemDerived } from 'types/item';

const ConfigurableFilter = (filter?: IConfigurableFilter): ItemFilter => {
    if (!filter) return () => true;

    const { highPrice, lowPrice, avgHighPrice, avgLowPrice, dailyVolume, buyLimit, margin, roi, avgMargin, avgRoi, potentialProfit, velocity, f2pOnly } = filter;

    return (value?: IOsrsItemDerived): boolean => {
        if (!value) return false;
        return (
            (value.latestPrice.highPrice ?? 0) <= (highPrice?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.latestPrice.highPrice ?? 0) >= (highPrice?.min ?? 0) &&
            (value.latestPrice.lowPrice ?? 0) <= (lowPrice?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.latestPrice.lowPrice ?? 0) >= (lowPrice?.min ?? 0) &&
            (value.priceMetadata.approxHourlyHighPrice ?? 0) <= (avgHighPrice?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.approxHourlyHighPrice ?? 0) >= (avgHighPrice?.min ?? 0) &&
            (value.priceMetadata.approxHourlyLowPrice ?? 0) <= (avgLowPrice?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.approxHourlyLowPrice ?? 0) >= (avgLowPrice?.min ?? 0) &&
            (value.priceMetadata.dailyVolume ?? 0) <= (dailyVolume?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.dailyVolume ?? 0) >= (dailyVolume?.min ?? 0) &&
            (value.metadata.buyLimit ?? 0) <= (buyLimit?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.metadata.buyLimit ?? 0) >= (buyLimit?.min ?? 0) &&
            (value.priceMetadata.margin ?? 0) <= (margin?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.margin ?? 0) >= (margin?.min ?? -1 * Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.roi ?? 0) <= (roi?.max ?? Number.MAX_SAFE_INTEGER) / 100 &&
            (value.priceMetadata.roi ?? 0) >= (roi?.min ?? -1 * Number.MAX_SAFE_INTEGER) / 100 &&
            (value.priceMetadata.approxHourlyMargin ?? 0) <= (avgMargin?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.approxHourlyMargin ?? 0) >= (avgMargin?.min ?? -1 * Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.approxHourlyRoi ?? 0) <= (avgRoi?.max ?? Number.MAX_SAFE_INTEGER) / 100 &&
            (value.priceMetadata.approxHourlyRoi ?? 0) >= (avgRoi?.min ?? -1 * Number.MAX_SAFE_INTEGER) / 100 &&
            (value.priceMetadata.potentialProfit ?? 0) <= (potentialProfit?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.potentialProfit ?? 0) >= (potentialProfit?.min ?? -1 * Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.dailyVelocity ?? 0) <= (velocity?.max ?? Number.MAX_SAFE_INTEGER) &&
            (value.priceMetadata.dailyVelocity ?? 0) >= (velocity?.min ?? -1 * Number.MAX_SAFE_INTEGER) &&
            (f2pOnly ? !value.metadata.members : true)
        );
    };
};

export default ConfigurableFilter;
