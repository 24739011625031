import fetch from 'node-fetch';

import { HistoryTimestep, IOsrsItemLatestPrice, IOsrsItemMetadata, IOsrsItemPriceSummary, SummaryLength } from 'types/item';

const OSRSWIKI_HOST = process.env.OSRSWIKI_HOST ?? 'https://prices.runescape.wiki';

interface ILatestPriceResponse {
    data: {
        [key: string]: {
            high?: number;
            highTime?: number;
            low?: number;
            lowTime?: number;
        };
    };
}

interface ISummaryPriceResponse {
    data: {
        [key: string]: {
            avgHighPrice?: number;
            highPriceVolume?: number;
            avgLowPrice?: number;
            lowPriceVolume?: number;
        };
    };
    timestamp: number;
}

interface IMappingResponse {
    id: number;
    name: string;
    examine: string;
    icon: string;
    members: true;
    limit?: number;
    value: number;
    lowalch: number;
    highalch: number;
}

interface ITimeseriesResponse {
    data: [
        {
            timestamp: number;
            avgHighPrice: number;
            avgLowPrice: number;
            highPriceVolume: number;
            lowPriceVolume: number;
        }
    ];
    itemId: number;
}

async function fetchMetadata(): Promise<Map<number, IOsrsItemMetadata>> {
    const response = await fetch(`${OSRSWIKI_HOST}/api/v1/osrs/mapping`);

    if (response.ok) {
        const responseJson: IMappingResponse[] = await response.json();
        const map = new Map<number, IOsrsItemMetadata>();
        for (let item of responseJson) {
            const metadata: IOsrsItemMetadata = {
                name: item.name,
                members: item.members,
                examine: item.examine,
                buyLimit: item.limit,
                storePrice: item.value,
                highAlchPrice: item.highalch,
                wikiIconUrl: item.icon.replaceAll(' ', '_'),
                tags: [],
            };
            map.set(item.id, metadata);
        }
        return map;
    } else {
        throw new Error(`Received non-200 status code from OSRS Wiki: ${response.status}`);
    }
}

async function fetchLatestPrices(): Promise<Map<number, IOsrsItemLatestPrice>> {
    const response = await fetch(`${OSRSWIKI_HOST}/api/v1/osrs/latest`);

    if (response.ok) {
        const responseJson: ILatestPriceResponse = await response.json();
        const map = new Map<number, IOsrsItemLatestPrice>();
        for (let key in responseJson.data) {
            const id = parseInt(key, 10);
            const data = responseJson.data[key];
            const price: IOsrsItemLatestPrice = {
                highPrice: data.high,
                highTime: data.highTime,
                lowPrice: data.low,
                lowTime: data.lowTime,
            };
            map.set(id, price);
        }
        return map;
    } else {
        throw new Error(`Received non-200 status code from OSRS Wiki: ${response.status}`);
    }
}

async function fetchSummaryPrices(duration: SummaryLength = '5m'): Promise<Map<number, IOsrsItemPriceSummary>> {
    const response = await fetch(`${OSRSWIKI_HOST}/api/v1/osrs/${duration}`);

    if (response.ok) {
        const responseJson: ISummaryPriceResponse = await response.json();
        const map = new Map<number, IOsrsItemPriceSummary>();
        for (let key in responseJson.data) {
            const id = parseInt(key, 10);
            const data = responseJson.data[key];
            const price: IOsrsItemPriceSummary = {
                avgHighPrice: data.avgHighPrice,
                avgLowPrice: data.avgLowPrice,
                highPriceVolume: data.highPriceVolume,
                lowPriceVolume: data.lowPriceVolume,
                timestamp: responseJson.timestamp,
            };
            map.set(id, price);
        }
        return map;
    } else {
        throw new Error(`Received non-200 status code from OSRS Wiki: ${response.status}`);
    }
}

async function fetchPastHourSummaries(): Promise<Map<number, IOsrsItemPriceSummary[]>> {
    const response = await fetch(`${OSRSWIKI_HOST}/api/v1/osrs/5m`);

    if (response.ok) {
        const responseJson: ISummaryPriceResponse = await response.json();
        const initialTimestamp = responseJson.timestamp;
        const responses = await Promise.all([...Array(11).keys()].map(i => fetch(`${OSRSWIKI_HOST}/api/v1/osrs/5m?timestamp=${initialTimestamp - (i + 1) * 300}`)))

        if (responses.find(r => !r.ok)) {
            throw new Error(`Received non-200 status code from OSRS Wiki: ${response.status}`);
        }

        const responseJsons: ISummaryPriceResponse[] = [responseJson, ...await Promise.all(responses.map(r => r.json()))]
        
        const itemIds = new Set(responseJsons.flatMap(j => Object.keys(j.data)))
        const map = new Map<number, IOsrsItemPriceSummary[]>();
        for (let key of itemIds) {
            const id = parseInt(key, 10);
            const prices = responseJsons
                .map(json => {
                    const data = json.data[id];
                    return {
                        avgHighPrice: data?.avgHighPrice,
                        avgLowPrice: data?.avgLowPrice,
                        highPriceVolume: data?.highPriceVolume,
                        lowPriceVolume: data?.lowPriceVolume,
                        timestamp: json.timestamp,
                    }
                })
            map.set(id, prices);
        }
        return map;
    } else {
        throw new Error(`Received non-200 status code from OSRS Wiki: ${response.status}`);
    }
}

async function fetchItemTimeseries(itemId: number, timestep: HistoryTimestep = '5m'): Promise<IOsrsItemPriceSummary[]> {
    const response = await fetch(`${OSRSWIKI_HOST}/api/v1/osrs/timeseries?id=${itemId}&timestep=${timestep}`);

    if (response.ok) {
        const responseJson: ITimeseriesResponse = await response.json();
        return responseJson.data.map(
            (data) =>
            ({
                avgHighPrice: data.avgHighPrice,
                avgLowPrice: data.avgLowPrice,
                highPriceVolume: data.highPriceVolume,
                lowPriceVolume: data.lowPriceVolume,
                timestamp: data.timestamp,
            } as IOsrsItemPriceSummary)
        );
    } else {
        throw new Error(`Received non-200 status code from OSRS Wiki: ${response.status}`);
    }
}

export { fetchLatestPrices, fetchSummaryPrices, fetchMetadata, fetchItemTimeseries, fetchPastHourSummaries };
