import classNames from 'classnames';
import RelativeTimestamp from 'components/RelativeTimestamp';
import { MouseEventHandler } from 'react';

interface ISubHeaderComponentProps {
    title: string;
    subtitle: string;
    lastUpdated?: Date;
    filterName?: string;
    filterActive: boolean;
    onFilterNameChange: (filterName: string) => any;
    onFilterClick: MouseEventHandler;
}

const SubHeaderComponent: React.FC<ISubHeaderComponentProps> = ({
    title,
    subtitle,
    lastUpdated,
    filterName,
    filterActive,
    onFilterNameChange,
    onFilterClick,
}) => {
    return (
        <div className="is-flex is-full-width is-space-between">
            <div>
                <h1 className="title">{title}</h1>
                <h2 className="subtitle">{subtitle}</h2>
            </div>
            <div className="is-flex-column is-flex-end is-space-between">
                <div className="is-text-right">
                    <em>
                        Last updated: <RelativeTimestamp timestamp={lastUpdated} />
                    </em>
                </div>
                <div>
                    <input type="text" placeholder="Filter by item..." onChange={(e) => onFilterNameChange(e.target.value)} value={filterName} />
                    <button className={classNames({ 'is-active': filterActive })} onClick={onFilterClick}>
                        <span className="icon fas fa-filter" />
                        <span>Filter Items</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubHeaderComponent;
