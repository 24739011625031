export default function simpleMovingAverage(data: (number | undefined)[], window: number): (number | undefined)[] {
    const sma: (number | undefined)[] = [];
    for (let i = 0; i < data.length; i++) {
        if (i < window) {
            sma[i] = undefined;
            continue;
        }

        if (data[i] === undefined) {
            sma[i] = sma[i - 1];
        } else {
            let sum = 0;
            let count = 0;
            for (let j = 0; j < window; j++) {
                if (data[i - j]) {
                    sum += data[i - j] ?? 0;
                    count++;
                }
            }
            sma[i] = Math.round(sum / count);
        }
    }
    return sma;
}
