import { Dispatch, SetStateAction, useState } from 'react';

export interface StringMapper<S> {
    toString(val: S): string;
    fromString(val: string): S;
}

const loadFromStorage = <S>(key: string, storage: Storage | undefined, mapper: StringMapper<S>, defaultValue: S): S => {
    console.log(`Loading ${key} from store w/ default value: ${defaultValue}`);
    const value = storage?.getItem(key) ?? undefined;
    return typeof value === 'undefined' ? defaultValue : mapper.fromString(value);
};

const saveToStorage = <S>(key: string, storage: Storage | undefined, mapper: StringMapper<S>, value: S) => {
    console.log(`Saving ${key} to store w/ value: ${value}`);
    if (typeof value === 'undefined') {
        storage?.removeItem(key);
    } else {
        storage?.setItem(key, mapper.toString(value));
    }
};

const DEFAULT_MAPPER: StringMapper<any> = {
    toString(value: any) {
        return JSON.stringify(value);
    },
    fromString(value: string) {
        return JSON.parse(value);
    },
};

export default function usePersistedState<S>(
    key: string,
    defaultValue: S,
    storage: Storage | undefined = window?.localStorage,
    mapper: StringMapper<S> = DEFAULT_MAPPER
): [S, Dispatch<SetStateAction<S>>, () => S] {
    const [value, dispatch] = useState<S>(() => loadFromStorage<S>(key, storage, mapper, defaultValue));
    return [
        value,
        (newValue: SetStateAction<S>) => {
            saveToStorage(key, storage, mapper, newValue);
            dispatch(newValue);
        },
        () => loadFromStorage(key, storage, mapper, defaultValue),
    ];
}
