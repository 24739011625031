import { useEffect, useReducer, Reducer } from 'react';

import cacheItem from 'hooks/useCache';

import { IOsrsItem, IOsrsItemLatestPrice, IOsrsItemMetadata, IOsrsItemPriceSummary, SummaryLength } from 'types/item';
import { fetchLatestPrices, fetchMetadata, fetchPastHourSummaries, fetchSummaryPrices } from 'data/osrswiki';
import useInterval from './useInterval';

enum Statuses {
    Loading = 'Loading',
    Refreshing = 'Refreshing',
    Loaded = 'Loaded',
}

interface IUseItemsResponse {
    items?: Map<number, IOsrsItem>;
    loading: boolean;
    status?: string;
}

interface IUseItemsParams {
    live?: boolean;
    refreshDuration?: number;
    itemIds?: number[];
}

function buildItems(
    latestPrices: Map<number, IOsrsItemLatestPrice>,
    metadatas: Map<number, IOsrsItemMetadata>,
    summaries: {
        [key in SummaryLength]: Map<number, IOsrsItemPriceSummary>;
    },
    pastHourSummaries: Map<number, IOsrsItemPriceSummary[]>
): Map<number, IOsrsItem> {
    const items = new Map<number, IOsrsItem>();
    const now = new Date();

    for (let [id, metadata] of metadatas) {
        const latestPrice = latestPrices.get(id);
        if (!latestPrice) continue;
        const item: IOsrsItem = {
            id,
            metadata,
            latestPrice,
            lastUpdated: now,
            summaries: {
                '5m': summaries['5m'].get(id),
                '1h': summaries['1h'].get(id),
                '6h': summaries['6h'].get(id),
                '24h': summaries['24h'].get(id),
            },
            pastHourSummaries: pastHourSummaries.get(id)
        };
        items.set(id, item);
    }

    return items;
}

interface ItemState {
    status: string;
    items?: Map<number, IOsrsItem>;
    promises: {
        latestPricePromise: Promise<Map<number, IOsrsItemLatestPrice>>;
        metadataPromise: Promise<Map<number, IOsrsItemMetadata>>;
        summaryPromises: {
            [key in SummaryLength]: Promise<Map<number, IOsrsItemPriceSummary>>;
        };
        pastHourPromise: Promise<Map<number, IOsrsItemPriceSummary[]>>;
    };
}

export default function useItems({ live = false, refreshDuration = 0, itemIds = undefined }: IUseItemsParams): IUseItemsResponse {
    // Combine the two state values using a reducer to minimize re-renders
    const [state, setState] = useReducer<Reducer<ItemState, Partial<ItemState>>>((state, newState) => ({ ...state, ...newState }), {
        status: Statuses.Loading,
        items: undefined,
        promises: {
            latestPricePromise: cacheItem('item-latest', () => fetchLatestPrices(), 5000)[0],
            metadataPromise: cacheItem('item-metadata', () => fetchMetadata(), 3600000)[0],
            summaryPromises: {
                '5m': cacheItem('item-price-summary-5m', () => fetchSummaryPrices('5m'), 60000)[0],
                '1h': cacheItem('item-price-summary-1h', () => fetchSummaryPrices('1h'), 300000)[0],
                '6h': cacheItem('item-price-summary-6h', () => fetchSummaryPrices('6h'), 1800000)[0],
                '24h': cacheItem('item-price-summary-24h', () => fetchSummaryPrices('24h'), 3600000)[0],
            },
            pastHourPromise: cacheItem('item-summary-past-hour', () => fetchPastHourSummaries(), 60000)[0]
        },
    });

    // Fetch non-latest prices and cache them for appropriate times

    useInterval(
        () => {
            const promises = {
                latestPricePromise: cacheItem('item-latest', () => fetchLatestPrices(), 5000)[0],
                metadataPromise: cacheItem('item-metadata', () => fetchMetadata(), 3600000)[0],
                summaryPromises: {
                    '5m': cacheItem('item-price-summary-5m', () => fetchSummaryPrices('5m'), 60000)[0],
                    '1h': cacheItem('item-price-summary-1h', () => fetchSummaryPrices('1h'), 300000)[0],
                    '6h': cacheItem('item-price-summary-6h', () => fetchSummaryPrices('6h'), 1800000)[0],
                    '24h': cacheItem('item-price-summary-24h', () => fetchSummaryPrices('24h'), 3600000)[0],
                },
                pastHourPromise: cacheItem('item-summary-past-hour', () => fetchPastHourSummaries(), 60000)[0]
            };
            setState({ promises, status: Statuses.Refreshing });
        },
        live ? refreshDuration : 0
    );

    useEffect(() => {
        (async () => {
            const items = buildItems(await state.promises.latestPricePromise, await state.promises.metadataPromise, {
                '5m': await state.promises.summaryPromises['5m'],
                '1h': await state.promises.summaryPromises['1h'],
                '6h': await state.promises.summaryPromises['6h'],
                '24h': await state.promises.summaryPromises['24h'],
            }, await state.promises.pastHourPromise);
            setState({
                items,
                status: Statuses.Loaded,
            });
        })();
    }, [state.promises]);

    return {
        items: state.items,
        loading: state.status === Statuses.Loading,
        status: state.status,
    };
}
