import classNames from 'classnames';
import { ItemsContext } from 'context/ItemsContext';
import { useContext } from 'react';

interface IWatchButtonProps {
    itemId: number;
}

const WatchButton: React.FC<IWatchButtonProps> = ({ itemId }) => {
    const { watched, toggleWatched } = useContext(ItemsContext);

    const isWatched = watched.has(itemId);

    return (
        <button onClick={() => toggleWatched(itemId)} className={classNames({ 'is-active': isWatched })}>
            <i className={classNames({ fas: isWatched, far: !isWatched, 'fa-eye': isWatched, 'fa-eye-slash': !isWatched })} />
        </button>
    );
};

export default WatchButton;
