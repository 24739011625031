interface CacheEntry<T> {
    value: T;
    lastUpdated: number;
    TTL: number | undefined;
}

// Purge expired keys every 5 minutes if not queried
const PURGE_INTERVAL = 300000;
const cache = new Map<string, CacheEntry<any>>();

setInterval(() => {
    const now = Date.now();
    for (let [key, entry] of cache) {
        if (entry.TTL && now - entry.lastUpdated > entry.TTL) {
            cache.delete(key);
        }
    }
}, PURGE_INTERVAL);

export default function cacheItem<T>(key: string, valueProvider: () => T, TTL: number = 60000): [T, () => void, (val: T) => boolean] {
    const updateCache = (val: T) => {
        if (cache.has(key)) {
            cache.get(key)!!.value = val;
            cache.get(key)!!.lastUpdated = Date.now();
            return true;
        }
        return false;
    };

    if (cache.has(key)) {
        const entry = cache.get(key)!!;
        const now = Date.now();
        if (entry.TTL && now - entry.lastUpdated > entry.TTL) {
            cache.delete(key);
        } else {
            return [entry.value, () => cache.delete(key), updateCache];
        }
    }

    const value = valueProvider();
    cache.set(key, {
        value: value,
        lastUpdated: Date.now(),
        TTL,
    });
    return [value, () => cache.delete(key), updateCache];
}
