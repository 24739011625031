import React, { Dispatch, SetStateAction } from 'react';

import usePersistedState, { StringMapper } from 'hooks/usePersistedState';

const PREFIX = 'item-context';
const FAVORITES_KEY = `${PREFIX}-favorites`;
const WATCHED_KEY = `${PREFIX}-watched`;

export interface IItemsContext {
    favorites: Set<number>;
    watched: Set<number>;
    toggleFavorite: (itemId: number) => any;
    toggleWatched: (itemId: number) => any;
}

const ItemsContext = React.createContext<IItemsContext>({
    favorites: new Set<number>(),
    watched: new Set<number>(),
    toggleFavorite: (itemId: number) => {},
    toggleWatched: (itemId: number) => {},
});

const SetMapper: StringMapper<Set<number>> = {
    toString(value: Set<number>): string {
        return JSON.stringify([...value]);
    },
    fromString(value: string): Set<number> {
        return new Set(JSON.parse(value));
    },
};

const toggleTrackedItem = (getLatest: () => Set<number>, setState: Dispatch<SetStateAction<Set<number>>>) => {
    return (itemId: number) => {
        const newTracked = new Set(getLatest());
        if (newTracked.has(itemId)) {
            newTracked.delete(itemId);
        } else {
            newTracked.add(itemId);
        }
        setState(newTracked);
    };
};

const ItemsContextProvider: React.FC = (props) => {
    const [favorites, setFavorites, loadLatestFavorites] = usePersistedState<Set<number>>(FAVORITES_KEY, new Set(), window.localStorage, SetMapper);
    const [watched, setWatched, loadLatestWatched] = usePersistedState<Set<number>>(WATCHED_KEY, new Set(), window.localStorage, SetMapper);

    const toggleFavorite = toggleTrackedItem(loadLatestFavorites, setFavorites);
    const toggleWatched = toggleTrackedItem(loadLatestWatched, setWatched);

    return <ItemsContext.Provider value={{ favorites, toggleFavorite, watched, toggleWatched }}>{props.children}</ItemsContext.Provider>;
};

export { ItemsContext, ItemsContextProvider };
