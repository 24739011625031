import { Fragment, useMemo, useState } from 'react';

import { Page } from 'types/page';
import DataTable, { createTheme, defaultThemes, IDataTableColumn } from 'react-data-table-component';
import { IOsrsItemDerived } from 'types/item';
import FormattedPercent from 'components/FormattedPercent';
import FormattedMargin from 'components/FormattedMargin';
import RelativeTimestamp from 'components/RelativeTimestamp';
import { Link } from '@reach/router';
import NameFilter from 'utils/filters/NameFilter';
import { IConfigurableFilter } from 'types/filters';

import './itemList.scss';
import ConfigurableFilter from 'utils/filters/ConfigurableFilter';
import usePersistedState from 'hooks/usePersistedState';
import FavoriteButton from 'components/FavoriteButton';
import WatchButton from 'components/WatchButton';
import ExpandableRowComponent from './ExpandableRow';
import SubHeaderComponent from './SubHeaderComponent';
import ItemFilterModal from './ItemFilterModal';
import classNames from 'classnames';

createTheme('custom', {
    ...defaultThemes.dark,
    text: {
        primary: '#cccccc',
        secondary: '#aaaaaa',
        disabled: '#888888',
    },
    background: {
        default: 'transparent',
    },
    button: {
        disabled: '#444',
    },
});

const IconCell = (item?: IOsrsItemDerived) => {
    return (
        <div data-tag="allowRowEvents">
            {item && <img alt={item.metadata.name} src={`https://oldschool.runescape.wiki/images/${item.metadata.wikiIconUrl}?`} className="icon" />}
        </div>
    );
};

const NumberCell = (getter: (item?: IOsrsItemDerived) => number | undefined) => (item?: IOsrsItemDerived) => {
    return <div data-tag="allowRowEvents">{getter(item)?.toLocaleString() ?? 'Unknown'}</div>;
};

const MarginCell = (getter: (item?: IOsrsItemDerived) => number | undefined) => (item?: IOsrsItemDerived) => {
    return (
        <div data-tag="allowRowEvents">
            <FormattedMargin margin={getter(item)} />
        </div>
    );
};

const PercentCell = (getter: (item?: IOsrsItemDerived) => number | undefined) => (item?: IOsrsItemDerived) => {
    return (
        <div data-tag="allowRowEvents">
            <FormattedPercent percent={getter(item)} />
        </div>
    );
};

const undefinedNumberSort =
    (valueExtractor: (item: IOsrsItemDerived | undefined) => number | undefined) =>
    (itemA: IOsrsItemDerived | undefined, itemB: IOsrsItemDerived | undefined): number => {
        const valA = valueExtractor(itemA);
        const valB = valueExtractor(itemB);
        const aUndef = typeof valA === 'undefined';
        const bUndef = typeof valB === 'undefined';

        if (aUndef && bUndef) return 0;
        else if (aUndef && !bUndef) return -1;
        else if (!aUndef && bUndef) return 1;
        else if (valA!! > valB!!) return 1;
        else if (valB!! > valA!!) return -1;

        return 0;
    };

const columns: IDataTableColumn<IOsrsItemDerived>[] = [
    {
        cell: IconCell,
        grow: 0,
        minWidth: '40px',
        compact: true,
    },
    {
        selector: (item) => item.metadata.members,
        cell: (item) => (<div data-tag="allowRowEvents members-cell">
            <span className="icon">
                <i className={classNames("fas fa-star",{'gold': item.metadata.members})}/>
            </span>
        </div>),
        grow: 0,
        minWidth: '40px',
    },
    {
        name: 'Name',
        selector: (item) => item.metadata.name,
        sortable: true,
        cell: (item?: IOsrsItemDerived) => <div data-tag="allowRowEvents">{item && <Link to={`/item/${item.id}`}>{item.metadata.name}</Link>}</div>,
        compact: true,
        grow: 2,
    },
    {
        name: 'Low Price',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.latestPrice.lowPrice),
        selector: (item) => item?.latestPrice.lowPrice,
        cell: (item) => {
            return (
                <div data-tag="allowRowEvents">
                    <div>{item.latestPrice?.lowPrice?.toLocaleString() ?? 'Unknown'}</div>
                    <div>
                        <small>
                            {item.latestPrice.lowTime && <RelativeTimestamp timestamp={new Date(item.latestPrice.lowTime * 1000)} />}
                            {!item.latestPrice.lowTime && 'Unknown'}
                        </small>
                    </div>
                </div>
            );
        },
        compact: true,
    },
    {
        name: 'High Price',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.latestPrice.highPrice),
        selector: (item) => item?.latestPrice.highPrice,
        cell: (item) => {
            return (
                <div data-tag="allowRowEvents">
                    <div>{item.latestPrice?.highPrice?.toLocaleString() ?? 'Unknown'}</div>
                    <div>
                        <small>
                            {item.latestPrice.highTime && <RelativeTimestamp timestamp={new Date(item.latestPrice.highTime * 1000)} />}
                            {!item.latestPrice.highTime && 'Unknown'}
                        </small>
                    </div>
                </div>
            );
        },
        compact: true,
    },
    {
        name: 'Margin',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.margin),
        selector: (item) => item?.priceMetadata.margin,
        cell: MarginCell((item) => item?.priceMetadata.margin),
        compact: true,
    },
    {
        name: 'ROI',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.roi),
        selector: (item) => item?.priceMetadata.roi,
        cell: PercentCell((item) => item?.priceMetadata.roi),
        compact: true,
    },
    {
        name: '1h⌖ Low Price',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.approxHourlyLowPrice),
        selector: (item) => item?.priceMetadata.approxHourlyLowPrice,
        cell: (item) => {
            return (
                <div data-tag="allowRowEvents">
                    <div>{item.priceMetadata.approxHourlyLowPrice?.toLocaleString() ?? 'Unknown'}</div>
                    <div>
                        <small>
                        {item.priceMetadata.approxHourlyLowPriceTimestamp && <RelativeTimestamp timestamp={new Date(item.priceMetadata.approxHourlyLowPriceTimestamp * 1000)} />}
                            {!item.priceMetadata.approxHourlyLowPriceTimestamp && 'Unknown'}
                        </small>
                    </div>
                </div>
            );
        },
        compact: true,
    },
    {
        name: '1h⌖ High Price',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.approxHourlyHighPrice),
        selector: (item) => item?.priceMetadata.approxHourlyHighPrice,
        cell: (item) => {
            return (
                <div data-tag="allowRowEvents">
                    <div>{item.priceMetadata.approxHourlyHighPrice?.toLocaleString() ?? 'Unknown'}</div>
                    <div>
                        <small>
                            {item.priceMetadata.approxHourlyHighPriceTimestamp && <RelativeTimestamp timestamp={new Date(item.priceMetadata.approxHourlyHighPriceTimestamp * 1000)} />}
                            {!item.priceMetadata.approxHourlyHighPriceTimestamp && 'Unknown'}
                        </small>
                    </div>
                </div>
            );
        },
        compact: true,
    },
    {
        name: '1h⌖ Margin',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.approxHourlyMargin),
        selector: (item) => item?.priceMetadata.approxHourlyMargin,
        cell: MarginCell((item) => item?.priceMetadata.approxHourlyMargin),
        compact: true,
    },
    {
        name: '1h⌖ ROI',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.approxHourlyRoi),
        selector: (item) => item?.priceMetadata.approxHourlyRoi,
        cell: PercentCell((item) => item?.priceMetadata.approxHourlyRoi),
        compact: true,
    },
    {
        name: 'Daily Volume',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.dailyVolume),
        selector: (item) => item?.priceMetadata.dailyVolume,
        cell: NumberCell((item) => item?.priceMetadata.dailyVolume),
        compact: true,
    },
    {
        name: 'Trade Limit',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.metadata.buyLimit),
        selector: (item) => item?.metadata.buyLimit,
        cell: NumberCell((item) => item?.metadata.buyLimit),
        maxWidth: '64px',
        compact: true,
    },
    {
        name: 'Margin * Daily Volume',
        sortable: true,
        sortFunction: undefinedNumberSort((item) => item?.priceMetadata.dailyVelocity),
        selector: (item) => item?.priceMetadata.dailyVelocity,
        cell: MarginCell((item) => item?.priceMetadata.dailyVelocity),
        compact: true,
    },
    {
        sortable: false,
        compact: true,
        cell: (item) => <FavoriteButton itemId={item.id} />,
        grow: 0,
        minWidth: '40px',
    },
    {
        sortable: false,
        compact: true,
        cell: (item) => <WatchButton itemId={item.id} />,
        grow: 0,
        minWidth: '40px',
    },
];

interface IItemListProps {
    items: IOsrsItemDerived[];
    title?: string;
    subtitle?: string;
    pageId?: string;
}

const ItemList: Page<IItemListProps> = ({ items: rawItems, title = 'Items', subtitle = '', pageId = 'default' }) => {
    const [filterName, setFilterName] = useState<string | undefined>();
    const [modalVisible, setModalVisible] = useState(false);
    const [filter, setFilter] = usePersistedState<IConfigurableFilter | undefined>(`item-configurable-filter-${pageId}`, undefined);

    const nameFilter = useMemo(
        () =>
            NameFilter({
                name: filterName,
                fuzzy: true,
            }),
        [filterName]
    );

    const configurableFilter = useMemo(() => ConfigurableFilter(filter), [filter]);

    const items: IOsrsItemDerived[] = useMemo(
        () =>
            rawItems
                .filter(nameFilter)
                .filter(configurableFilter)
                .filter((value) => typeof value != 'undefined'),
        [rawItems, nameFilter, configurableFilter]
    ) as IOsrsItemDerived[];

    const lastUpdated = useMemo(() => items[0]?.lastUpdated, [items]);

    return (
        <Fragment>
            <DataTable
                columns={columns}
                data={items}
                theme="custom"
                expandableRows
                expandableRowsComponent={<ExpandableRowComponent />}
                expandOnRowClicked
                expandableRowsHideExpander
                highlightOnHover
                defaultSortField="Name"
                pagination
                paginationRowsPerPageOptions={[15, 30, 50, 100]}
                paginationPerPage={15}
                noHeader
                subHeader
                subHeaderWrap={true}
                responsive={false}
                subHeaderComponent={
                    <SubHeaderComponent
                        title={title}
                        subtitle={subtitle}
                        lastUpdated={lastUpdated}
                        filterName={filterName}
                        filterActive={!!filter}
                        onFilterNameChange={setFilterName}
                        onFilterClick={() => {
                            setModalVisible(true);
                        }}
                    />
                }
            />
            <ItemFilterModal
                active={modalVisible}
                filter={filter}
                onClose={(filterChanged, newFilter) => {
                    setModalVisible(false);
                    if (filterChanged) {
                        setFilter(newFilter);
                    }
                }}
            />
        </Fragment>
    );
};

export default ItemList;
