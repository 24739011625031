type unsafeNumber = number | undefined;

export function safeAdd(val1: unsafeNumber, val2: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined' || val1 === null || val2 === null) return undefined;

    return val1 + val2;
}

export function safeSubtract(val1: unsafeNumber, val2: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined' || val1 === null || val2 === null) return undefined;

    return val1 - val2;
}

export function safeMultiply(val1: unsafeNumber, val2: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined' || val1 === null || val2 === null) return undefined;

    return val1 * val2;
}

export function safeDivide(val1: unsafeNumber, val2: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined' || val1 === null || val2 === null) return undefined;

    return val1 / val2;
}

export function safeMin(val1: unsafeNumber, val2: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined' || val1 === null || val2 === null) return undefined;

    return Math.min(val1, val2);
}

export function safeMax(val1: unsafeNumber, val2: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined' || val1 === null || val2 === null) return undefined;

    return Math.max(val1, val2);
}

export function safeFloor(val1: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || val1 === null) return undefined;

    return Math.floor(val1);
}

export function safeCeil(val1: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || val1 === null) return undefined;

    return Math.ceil(val1);
}

export function safeRound(val1: unsafeNumber): unsafeNumber {
    if (typeof val1 === 'undefined' || val1 === null) return undefined;

    return Math.round(val1);
}
